<template>
  <div :class="$style.root" ref="rootElement">
    <ul :class="$style.list" ref="listElement">
      <li
        v-for="thumbnail in thumbnails"
        :key="thumbnail.id"
        ref="itemElement"
      >
        <SharedThumbnailPublicationComponent
          :data="thumbnail"
          :class="$style.image"
        />
      </li>
    </ul>

    <client-only>
      <LayoutHorizontalLineComponent
        v-for="position in barPositions"
        :key="position"
        :class="$style.bar"
        :style="{
          '--bottom': position + 'px'
        }"
      />
    </client-only>
  </div>
</template>

<script setup>
import { groupBy, min, range, inRange } from 'lodash-es'
const { width: windowWidth, height: windowHeight } = useWindowSize()

const props = defineProps({
  thumbnails: Array
})

// 1

const rootElement = ref(null)
const listElement = ref(null)
const itemElement = ref(null)

const barPositions = ref(null)
const setBarPositions = () => {
  const positions = itemElement.value?.map((item) => {
    return {
      target: item,
      left: item?.getBoundingClientRect()?.left,
      bottom: (item?.getBoundingClientRect()?.bottom - listElement.value.getBoundingClientRect().top)
    }
  })

  const verticalGroups = groupBy(positions, 'bottom')
  barPositions.value = Object.values(verticalGroups)?.map((group) => {
    return min(group?.map(i => i?.bottom))
  })
}

watch([windowWidth, windowWidth], () => {
  setBarPositions()
})

const interval = ref(null)
onMounted(() => {
  setTimeout(() => {
    rootElement.value.toggleAttribute('data-loaded', true)
  }, 250)
  interval.value = setInterval(() => {
    setBarPositions()
  }, 100)
})

onBeforeUnmount(() => {
  clearInterval(interval.value)
})
</script>

<style module>
.root {
  position: relative;

  display: block;
  margin: 0 var(--unit--horizontal--tripple);
}

.root:not([data-loaded]) {
  opacity: 0;
}

.list {
  composes: reset-list from global;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;

  gap: var(--unit--horizontal--tripple);

  padding-bottom: calc(var(--stroke--thick) * 1);
}

.bar {
  position: absolute;
  left: 0;
  right: 0;
  top: var(--bottom);
  margin-bottom: var(--stroke--thick);
}

.image {
  z-index: 1;
  position: relative;
}
</style>

export const thumbnailPublicationEntry = {
  fields: [
    'date_published',
    'width_in_mm',
    'thickness'
  ],
  populate: {
    titles: true,
    slugs: true,
    cover_image: true
  }
}

import qs from 'qs'

export const queryPublicationEntryThumbnails = (options: QueryManyOptions) => {
  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      sort: options?.sort || ['date_published:desc'],
      filters: options?.filters || [],
      pagination: {
        page: options?.page || 1,
        pageSize: options?.pageSize || 9,
      },
      ...thumbnailPublicationEntry
    },
    {
      encodeValuesOnly: true
    }
  )

  return `publication-entries?${query}`
}
